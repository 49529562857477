* {
  box-sizing: border-box;
}

body {
  background-color: #e4f9f5;
  font-family: "Verdana", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-family: "Georgia", serif;
}
.self-intro {
  width: 400px;
  margin: 30px auto;
  padding: 50px auto;
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}
.about-me {
  text-align: center;
}

.contact-me {
  text-align: center;
}

.skills {
  display: flex;
  width: auto;
  margin: 0 50px;
  text-align: center;
}

.skill-card {
  min-width: 33.33%;
  padding: 20px;
}

.certificates {
  display: flex;
  height: 300px;
}

.certificate-img {
  height: 180px;
  margin: 20px;
  border-radius: 15px;
}

.certificate-img:hover {
  height: 250px;
}
